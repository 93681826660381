.profile-image {
    height: 200px;
    object-fit: cover;
}

.profile-card {
    min-width: 200px !important;
}

.course-picture {
    width: 100%
}