.article-card {
    width: 340px;
    height: 340px;
    position: relative;
    border-radius: 10px;
    transition: 0.25s;
    box-shadow: 5px 5px 4px #888888;
}

.article-card:hover {
    box-shadow: 5px 5px 4px #444444;
}

.article-card .article-description {
    color: white;
    position: absolute;
    bottom: 0px;
    padding: 15px;
}

.hero-image {
    width: 100%;
    height: 300px;
    position: relative;
    color: white;
}

.background-screen {
    background-color: rgba(0,0,0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-title {
    z-index: 2;
}

.article-body img {
    max-width: calc(max(600px, 100%));
    max-height: 400px;
    
}