.cover-banner {
    background-image: url("../../content/CoverImage.jpg");
    height: 100%;
    width: 100%;
    filter: blur(4px) grayscale(20%);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
}

.cover {
    height: calc(100vh - 112px);
    width: 100%;
    position: relative;
}

.cover-text {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(1,16,15, 0.84); /* Black w/opacity/see-through */
    color: white;
    border: 3px solid #f1f1f1;
    position: absolute;
    width: 90%;
    max-width: 400px;
    text-align: center;
    padding: 20px;
    top: 45%;
    left: 50%;
    transform: translate(-50%,0);
}

.supporter-logo {
    width: 100%;
    max-width: 250px;
    padding: 10px;
}

.rotate {
    transform: rotate(-90deg);
}