:root {
    --brand-blue: #032859;
    --brand-blue-secondary: #1e3d58;
    --brand-green: #1B998B;
    --brand-green-secondary: #44BBA4;
    --brand-yellow: #fab529;
}

.brand-blue {
    color: var(--brand-blue);
}
.brand-blue-secondary {
    color: var(--brand-blue-secondary);
}
.brand-yellow {
    color: var(--brand-yellow);
}

.top-buffer { margin-top:20px; }

.xl-top-buffer { margin-top:60px; }

.center-text {
    text-align: center;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}